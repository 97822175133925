body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(222, 222, 222);
}

.root {
  display: flex;
    min-height: 100vh;
    flex-direction: column;
}

main {
  flex: 1 0 auto;
}

.main-container {
 padding-bottom: 1rem;
}

nav .brand-logo {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
    padding-left: 1rem;
}

.outer-construction{
  max-height: 90%;
}

.under-construction {
  font-family: 'Saira Stencil One', cursive;
  background-color: rgb(247, 247, 92);
  color: rgb(48, 48, 48);
  padding: 0 1.5rem;
  font-size: 1.2rem;
  border: rgb(48, 48, 48);
  border-style: solid;
  border-width: thick;
  border-color: rgb(48, 48, 48);
  
}

.linkedin-container{
  margin-top: 2rem;
}